import React from "react"
import { Link, graphql } from "gatsby"
import NYPressRelease from "../components/common/NYPressRelease"
import TestimonialImage from "../components/common/TestimonialImage"
import SEO from "../components/seo"
import {
  ScH1,
  ScH3,
  ScText,
  ScGridRow,
  ScGridCol,
  ScPageWrapper,
} from "../components/StyledComponents/elements"

const NewsPage = props => {
  console.log(props)

  return (
    <ScPageWrapper>
      <SEO title="News" />
      <ScH1>News</ScH1>
      <ScGridRow>
        <ScGridCol span={12}>
          <ScH3>New York Metro</ScH3>
          <ScText fontSize="data-text">
            GrandMaster Melle Mel, Author, Rap Legend Wrestling with rap,
            children
          </ScText>
        </ScGridCol>
      </ScGridRow>
      <ScGridRow justifyContent="center">
        <NYPressRelease />
      </ScGridRow>
      <ScGridRow justifyContent="center">
        <ScGridCol span={12}>
          <ScH3>St.Vincent Indianapolis Hospital</ScH3>
        </ScGridCol>
      </ScGridRow>
      <ScGridRow justifyContent="center">
        <TestimonialImage />
      </ScGridRow>
    </ScPageWrapper>
  )
}

export const query = graphql`
  {
    allBook {
      edges {
        node {
          summary
          title
          localImage {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          id
          author {
            name
          }
        }
      }
    }
  }
`

export default NewsPage
