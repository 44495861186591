import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ScHeaderImage = styled(Img)`
  max-width: 700px;
  display: block;
  width: 100%;
`

function NYPressRelease() {
  const nyPressRelease = useStaticQuery(graphql`
    {
      fileName: file(relativePath: { eq: "ny_press_release.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <ScHeaderImage fluid={nyPressRelease.fileName.childImageSharp.fluid} />
}

export default NYPressRelease
